$(document).ready(function(){
    $('input').iCheck({
        checkboxClass: 'icheckbox_minimal-minimal_w',
        radioClass: 'icheckbox_minimal-minimal_w',
        increaseArea: '20%' // optional
    });

    $('.choice img').click(function(){
        var val = $(this).data('option');
       $('.check input[value=' + val +']').iCheck('check');
        return false;
    });
});